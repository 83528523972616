<template>
  <div class="box fadeIn">
    <van-nav-bar
      :title="title"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="999"
    />
    查询方式：
    <van-row style="display: flex; align-items: center">
      <van-col>
        <van-radio-group
          v-model="radio"
          style="display: flex"
          @click.native="onetrendChange"
        >
          <van-radio :name="1">日查询</van-radio>
          <van-radio :name="2" v-show="options.length">班查询</van-radio>
        </van-radio-group>
      </van-col>
      <van-col>
        <el-date-picker
          style="width: 130px"
          :picker-options="pickerOption"
          v-model="getForm.date"
          type="date"
          @change="
            (getForm.current = 1),
              getMaxTime(),
              getPageStatus(),
              selectDayTags()
          "
          value-format="yyyy-MM-dd"
          size="mini"
          placeholder="选择日期"
        >
        </el-date-picker>
        <el-select
          style="width: 80px"
          size="mini"
          placeholder="请选择"
          class="animated"
          :class="{ fadeOutUp: radio == 1, fadeInUp: radio == 2 }"
          v-model="currentShift"
          @change="getMaxTime()"
        >
          <el-option
            v-for="item in options"
            :key="item.shiftId"
            :label="item.shiftName"
            :value="item.shiftId"
          >
          </el-option>
        </el-select>
      </van-col>
    </van-row>
    <div class="box_item">
      <span style="font-weight: 700">设备运行状态时序图</span
      ><span v-if="isrunStatus"> </span>
      <div style="width: 100%; height: 80px">
        <van-empty
          image-size="50"
          v-if="isrunStatus"
          class="custom-image"
          image="https://ts1.cn.mm.bing.net/th/id/R-C.6653e44964f0b978d5098623a6397a64?rik=%2f4MtTbzxeGuCgQ&riu=http%3a%2f%2fbestfyl.com%2f_nuxt%2fimg%2f6653e44.png&ehk=M0yxp9nl50R42hNcrfIxz1rH3e34sQiC858q5FSZtmI%3d&risl=&pid=ImgRaw&r=0"
          description="暂无数据"
        />
        <div v-else id="oeeBody" ref="oeeBody" class="echarts_box"></div>
      </div>
    </div>
    <!-- 运行时长统计 -->
    <div class="box_item">
      <H3>设备时用电量</H3>
      <div style="width: 100%; height: 150px">
        <div class="echarts_box" id="DurationAll"></div>
      </div>
    </div>
    <!-- 运动状态 -->
    <div class="box_item" style="display: flex">
      <div style="width: 60%; height: 150px">
        <div class="echarts_box" id="pie"></div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 20px;
          width: 40%;
        "
      >
        <p>总时长：<br /><h2 style="padding-left: 10px;">{{ raleData.EDUR | timeFilter }}</h2></p>
        <p>总用电量：<br /><h2 style="padding-left: 10px;">{{ raleData.EVALUE }}kw.h</h2></p>
      </div>
    </div>
    <div class="box_item">
      <div style="height: 320px">
        <h3>设备运行状态明细</h3>
        <el-table height="250" :data="tableData" style="width: 100%">
          <el-table-column align="center" prop="startTime" label="开始时间">
            <template slot-scope="{ row }">
              {{ row.startTime | durationFilters }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="runStatus" label="状态">
            <template slot-scope="{ row }">
              <span v-if="row.runStatus == 1">
                <i class="el-icon-s-opportunity" style="color: #a7dc5a"></i>
                待机
              </span>
              <span v-if="row.runStatus == 2">
                <i class="el-icon-s-opportunity" style="color: #f3591c"></i>
                异常
              </span>
              <span v-if="row.runStatus == 3">
                <i class="el-icon-s-opportunity" style="color: #32a756"></i>
                运行
              </span>
              <span v-if="row.runStatus == 0">
                <i class="el-icon-s-opportunity" style="color: gray"></i>
                离线
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="duration" label="运行时长">
            <template slot-scope="{ row }">
              {{ row.duration | timeFilters }}
            </template>
          </el-table-column>
        </el-table>
        <van-pagination
          v-model="runStatus.current"
          :page-count="runStatus.total"
          mode="simple"
          @change="getPageStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let that;
export default {
  data() {
    return {
      raleData: {},
      nowShiftId: "",
      min: "",
      max: "",
      currentShift: "",
      getForm: {
        current: 1,
        date: "",
      },
      options: [],
      radio: 1,
      pickerOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      hourmyChart: "",
      timeDate: "",
      tableData: [],
      hourList: [],
      hourDate: "",
      runStatus: {
        current: 1,
        size: 10,
        total: 0,
        projectId: this.$route.query.projectId * 1,
        date: "",
      },
      isrunStatus: false,
      title: this.$route.query.pjName,
      zoom: 1,
      loading: false,
      setpie(data, ref) {
        let option = {
          tooltip: {
            trigger: "item",
            formatter: "{b}:{d}%",
          },
          // legend: {
          //   orient: "horizontal",
          //   top: "top",
          // },
          series: [
            {
              startAngle: 0,
              name: "运行状态",
              type: "pie",
              center: ["45%", "45%"],
              radius: "50%",
              labelLine: {
                show: true,
                length:3,
                length2: 3,
              },
              label: {
                show: true,
                // position: "center",
                formatter: "{b}:{d}%",
                fontSize:10
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: "bold",
                },
              },
              data: data,
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setOee(data) {
        let min = this.min;
        let max = this.max;
        function renderItem(params, api) {
          var categoryIndex = api.value(0);
          var start = api.coord([api.value(1), categoryIndex]);
          var end = api.coord([api.value(2), categoryIndex]);
          var height = api.size([0, 1])[1] * 0.8;
          var rectShape = echarts.graphic.clipRectByRect(
            {
              x: start[0],
              y: start[1] - height / 2,
              width: end[0] - start[0],
              height: height,
            },
            {
              x: params.coordSys.x,
              y: params.coordSys.y,
              width: params.coordSys.width,
              height: params.coordSys.height,
            }
          );
          return (
            rectShape && {
              type: "rect",
              transition: ["shape"],
              shape: rectShape,
              style: api.style(),
            }
          );
        }
        let option = {
          tooltip: {
            trigger: "item",
            // position: "bottom",
            confine: true,
            formatter: function (params) {
              let date = new Date(params.value[1]);
              let Y = date.getFullYear() + "-";
              let M =
                (date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1) + "-";
              let D =
                (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
                " ";

              let h =
                (date.getHours() < 10
                  ? "0" + date.getHours()
                  : date.getHours()) + ":";
              let m =
                (date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes()) + ":";
              let s =
                date.getSeconds() < 10
                  ? "0" + date.getSeconds()
                  : date.getSeconds();
              let strDate = Y + M + D + h + m + s;
              strDate = strDate.replace(/-/g, "/");
              let date1 = new Date(params.value[2]);
              let str = params.marker + params.name + ": ";
              if (params.value[3] / 1000 / 3600 >= 1) {
                str +=
                  parseInt(
                    (params.value[3] % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                  ) + "时";
              }
              if (((params.value[3] / 1000) % 3600) / 60 >= 1) {
                str +=
                  parseInt((params.value[3] % (1000 * 60 * 60)) / (1000 * 60)) +
                  "分";
              }
              str +=
                (params.value[3] % (1000 * 60)) / 1000 +
                "秒" +
                `<br/>` +
                params.marker +
                params.name +
                ": " +
                strDate +
                " - " +
                date1.toLocaleString("chinese", { hour12: false });
              if (!params.name) {
                str = "";
              }
              return str;
            },
            // axisPointer: {
            //   type: "cross",
            // },
          },
          dataZoom: [
            {
              type: "inside",
              filterMode: "weakFilter",
              showDataShadow: false,
              top: 0,
              labelFormatter: "",
              //  start: 99,
              // end: 100,
            },
          ],
          grid: {
            left: "5%",
            top: "2%",
            bottom: "30",
          },
          xAxis: {
            min,
            max,
            show: true,
            type: "time",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            data: [""],
            axisLine: {
              show: false,
            },
          },
          series: [
            {
              type: "custom",
              renderItem: renderItem,
              // itemStyle: {
              //   opacity: 1,
              // },
              encode: {
                x: [1, 2],
                y: 0,
              },
              data: data,
            },
          ],
        };
        var chartDom = this.$refs.oeeBody;
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
        this.chart = myChart;
      },
    };
  },
  filters: {
    timeFilters(data) {
      let str = "";
      if (data / 3600 >= 1) {
        str = str + (data - (data % 3600)) / 3600 + "小时";
      }
      if ((data % 3600) / 60 >= 1) {
        str = str + ((data % 3600) - (data % 60)) / 60 + "分";
      }
      str = str + (data % 60) + "秒";
      return str;
    },
    durationFilters(data) {
      return data.slice(10, data.length);
    },
  },
  methods: {
    selectDayTags(val) {
      if (val == that.hourDate) return;
      if (!that.timeDate) {
        let date = new Date();
        let full = date.getFullYear();
        let month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        that.timeDate = full + "-" + month + "-" + Day;
      }

      let from = {
        paramTags: ["HE"],
        projectId: this.$route.query.projectId * 1,
        date: that.getForm.date,
        historyType: 2,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let xData = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24"
          ];
          let obj = {};
          res.data.data[0].stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          xData.forEach((item, index) => {
            let num = obj[item] ? obj[item] : 0;
            // if (item == 0) {
            //   num = obj["24"] ? obj["24"] : 0;
            // }
            data.push(num);
            if (that.hourmyChart) {
              that.hourList[index].value = num;
            } else {
              that.hourList.push({ label: item, value: num, value1: 0 });
            }
          });
          if (that.hourmyChart) {
            let series = this.hourmyChart.getOption().series;
            series[0].data = data;
            this.hourmyChart.setOption({
              series: series,
            });
          } else {
            that.getBar(data, xData, "DurationAll");
          }
        }
      });
    },
    getMaxTime() {
      if (this.radio == 1) {
        this.min = `${this.dayjs(this.getForm.date).format(
          "YYYY-MM-DD"
        )} 00:00:00`;
        this.max = `${this.dayjs(this.getForm.date)
          .add(1, "day")
          .format("YYYY-MM-DD")} 00:00:00`;
      } else {
        let nowTime = this.options.filter(
          (item) => item.shiftId == this.currentShift
        )[0];
        this.min = `${this.dayjs(this.getForm.date).format("YYYY-MM-DD")} ${
          nowTime.startTime
        }:00`;
        if (Number(nowTime.nextDay) > 0) {
          this.max = `${this.dayjs(this.getForm.date)
            .add(1, "day")
            .format("YYYY-MM-DD")} ${nowTime.endTime}:00`;
        } else {
          this.max = `${this.dayjs(this.getForm.date).format("YYYY-MM-DD")} ${
            nowTime.endTime
          }:00`;
        }
      }
      this.getOee();
    },
    onetrendChange() {
      if (this.radio == 1) {
        this.nowShiftId = this.currentShift;
        this.currentShift = "";
        this.min = `${this.dayjs(this.getForm.date).format(
          "YYYY-MM-DD"
        )} 00:00:00`;
        this.max = `${this.dayjs(this.getForm.date)
          .add(1, "day")
          .format("YYYY-MM-DD")} 00:00:00`;
        that.getOee();
      } else {
        this.currentShift = this.nowShiftId;
        this.getMaxTime();
      }
    },
    formatter(val) {
      switch (val.runStatus) {
        case 0:
          return "离线";
        case 1:
          return "待机";
        case 2:
          return "低负荷";
        case 3:
          return "高负荷";
        default:
          return "离线";
        
      }
    },
    getPageStatus() {
      this.$api.energy.getPageState(this.runStatus).then((res) => {
        if (res.code == 200) {
          this.runStatus.total = res.data.pages;
          this.tableData = res.data.records;
        }
      });
    },
    getOee() {
      that.loading2 = true;
      that.isrunStatus = true;
      that.runStatus.date = this.getForm.date;
      this.getPageStatus();
      this.getDuration({
        projectId: this.$route.query.projectId * 1,
        date: this.getForm.date,
        shiftId: this.currentShift,
      });
      that.$api.energy
        .getEnergyOee({
          projectId: this.$route.query.projectId * 1,
          date: this.getForm.date,
          type: this.currentShift ? 2 : 1,
          shiftId: this.currentShift,
        })
        .then((res) => {
          that.loading2 = false;
          if (res.code == 200) {
            if (res.data.length) {
              that.isrunStatus = false;
            }
            this.$nextTick(() => {
              this.getPie(res.data);
              this.setHietory(res.data);
            });
          }
        });
    },
    getBar(data, XData, ref) {
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        // toolbox: {
        //   right: "3%",
        //   orient: "vertical",
        //   feature: {
        //     magicType: { show: true, type: ["line", "bar"] },
        //   },
        // },
        grid: {
          top: "15%",
          left: "10%",
          bottom: "15%",
          right: "5%",
        },
        dataZoom: [
            {
              type: "inside",
              xAxisIndex: [0],
              start: 0,
              end: 100,
            },
            // {
            //   type: "slider", //给x轴设置滚动条
            //   show: true, //flase直接隐藏图形
            //   yAxisIndex: [0],
            //   bottom: 0,
            //   height: 40,
            //   showDetail: false,
            //   startValue: 0, //滚动条的起始位置
            //   endValue: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
            // },
          ],
        xAxis: {
          type: "category",
          data: XData,
          axisLabel: {
            inside: false,
            color: "#394b5f",
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
          },
          axisLabel: {
            inside: false,
            color: "#394b5f",
          },
          splitLine: {
            lineStyle: {
              color: "#394b5f",
              width: 0.4,
            },
          },
        },
        series: [
          {
            color: "#3980fd",
            data: data,
            type: "bar",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: -0.2,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(86, 148, 252, 1)",
                  },
                  {
                    offset: 1,
                    //  color: 'rgba(255, 230, 0, 0.2)'
                    color: "rgba(86, 148, 252, 0.1)",
                  },
                ],
              },
            },
            label: {
              show: true,
              position: "top",
              color: "#394b5f",
              fontSize: 8,
            },
          },
        ],
      };

      var chartDom = document.getElementById(ref);
      var myChart = echarts.init(chartDom);
      this.hourmyChart = myChart;
      myChart.clear();
      myChart.setOption(option);
    },
    getPie(data) {
      let warning = 0;
      let wait = 0;
      let run = 0;
      let gray = 0;
      data.forEach((item) => {
        if (item.runStatus == 1) {
          warning += item.duration;
        } else if (item.runStatus == 3) {
          run += item.duration;
        } else if (item.runStatus == 2) {
          wait += item.duration;
        } else {
          gray += item.duration;
        }
      });
      let pieData = [];
      pieData.push(
        { name: '异常', value: warning, itemStyle: { color: "#f3591c" } },
        { name:  '待机', value: wait, itemStyle: { color: "#a7dc5a" } },
        { name: '运行', value: run, itemStyle: { color: "#32a756" } },
        { name:  '离线', value: gray, itemStyle: { color: "gray" } }
      );
      this.loading = true;
      this.$nextTick(() => {
        this.setpie(pieData, "pie");
      });
    },
    setHietory(data) {
      data = data.reverse();
      if (data.length == 0) {
        let chartDom = document.getElementById("oeeBody");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        return;
      }
      // 每天开始时间
      let startTime = +new Date(
        data[data.length - 1].startTime.split(" ")[0] + " " + "00:00:01"
      );
      // 每天的结束时间
      let endTime = +new Date(
        data[data.length - 1].startTime.split(" ")[0] + " " + "23:59:59"
      );
      let historyData = [];
      for (let i = data.length - 1; i >= 0; i--) {
        let date = +new Date(data[i].startTime); //每条数据上传的时间
        let item = data[i];
        let time = +new Date(data[data.length - 1].startTime); //第一次上传的时间
        if (i == data.length - 1 && startTime < time) {
          //如果每天的开始时间小于第一次上传的时间
          historyData.push({
            name: "",
            value: [
              "",
              startTime,
              (startTime += time - startTime),
              time - startTime,
            ],
            itemStyle: {
              color: "transparent",
            },
          });
        }
        let name, color;
        if (item.runStatus == 1) {
          name = "异常";
          color = "#f3591c";
        } else if (item.runStatus == 2) {
          name = "待机";
          color = "#a7dc5a";
        } else if (item.runStatus == 3) {
          name = "运行";
          color = "#32a756";
        } else if (item.runStatus == 0) {
          name = "离线";
          color = "gray";
        }
        historyData.push({
          name: name,
          value: [
            "",
            date,
            (date += item.duration * 1000),
            item.duration * 1000,
          ],
          itemStyle: {
            color: color,
          },
        });
        if (i == 0 && endTime > date + item.duration * 1000) {
          historyData.push({
            value: ["", date, (date += endTime - date), endTime - date],
            itemStyle: {
              color: "transparent",
            },
          });
        }
      }
      this.$nextTick(() => {
        this.setOee(historyData);
      });
    },
    getDuration(data) {
      this.$api.energy.getDuration(data).then((res) => {
        if (res.code == 200) {
          this.raleData = res.data;
        }
      });
    },
    async getShift() {
      await this.$api.triColorLamp
        .getShift(this.$route.query.projectId)
        .then((res) => {
          if (res.code == 200) {
            this.options = res.data.ShiftList || [];
            this.currentShift = res.data.shiftId || "";
            if (this.currentShift) {
              this.radio = 2;
              let nowTime = this.options.filter(
                (item) => item.shiftId == this.currentShift
              )[0];
              this.min = `${this.dayjs(this.getForm.date).format(
                "YYYY-MM-DD"
              )} ${nowTime.startTime}:00`;
              if (Number(nowTime.nextDay) > 0) {
                this.max = `${this.dayjs(this.getForm.date)
                  .add(1, "day")
                  .format("YYYY-MM-DD")} ${nowTime.endTime}:00`;
              } else {
                this.max = `${this.dayjs(this.getForm.date).format(
                  "YYYY-MM-DD"
                )} ${nowTime.endTime}:00`;
              }
            } else {
              this.radio = 1;
              this.min = `${this.dayjs(this.getForm.date).format(
                "YYYY-MM-DD"
              )} 00:00:00`;
              this.max = `${this.dayjs(this.getForm.date)
                .add(1, "day")
                .format("YYYY-MM-DD")} 00:00:00`;
            }
          }
        });
      that.getOee();
    },
  },
  created() {
    that = this;
    this.getForm.date = this.dayjs().format("YYYY-MM-DD");
    this.getShift();
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener("resize", () => {
      this.zoom = 1 / document.body.style.zoom;
    });
    that.selectDayTags();
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: left !important;
}
html,
body {
  height: 100%;
}
.box {
  padding: 50px 10px 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 50px;
}
.echarts_box {
  width: 100%;
  height: 100%;
}
/* .custom-image ::v-deep .van-empty__image {
  width: 30px;
  height: 30px;
} */
.big {
  font-size: 18px;
}
.DateState {
  font-size: 16px !important;
  color: #5470c6;
  font-weight: 700;
}
.van-row {
  border-bottom: 1px solid #ccc;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::v-deep .van-empty__description {
  font-size: 12px !important;
  margin-top: 0 !important;
}
.Panel {
  width: 97%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 0 10px;
  background: linear-gradient(to bottom, #9ae2e9, #e8f8fa);
}
.box_item1 .box {
  background-color: #fff;
  padding: 10px 10px;
}
.Panel_item {
  text-align: center !important;
  height: 25%;
  width: 100%;
  padding-left: 20px;
}
.Panel_item span {
  display: inline-block;
  width: 20%;
}
.percent {
  margin-left: 5px;
  text-align: center;
  line-height: 1;
}
.percent p {
  font-size: 16px;
  font-weight: 700;
}
.percent span {
  font-size: 12px;
  color: #666;
}
.box_item {
  margin-bottom: 10px;
  /* border:1px solid #ccc; */
  padding: 10px;
  border-radius: 15px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.box_item h3 {
  margin-bottom: 5px;
}
</style>
